export const general = {
    title: '总则',
    desc: [
        {
            paragraph: '北京爱比熊科技有限公司（以下简称“爱比熊”）是快客多（kuaikeduo.kuaizhan.com）网站的合法所有权方及运营方。快客多服务协议（以下简称“本协议”）是由快客多用户与爱比熊就各项服务所订立的相关权利义务规范。用户通过访问和/或使用本网站，即表示接受并同意本协议的所有条件和条款。爱比熊作为快客多网站（kuaikeduo.kuaizhan.com）的运营者依据本协议为用户提供服务。不愿接受本协议条款的，不得访问或使用本网站。',
        },
        {
            paragraph: '爱比熊有权对本协议条款进行修改，修改后的协议一经公示即有效代替原有协议。用户可随时查阅最新协议。',
        },
        {
            paragraph: '爱比熊向用户提供免费或收费服务，用户有权依据自身需求，自主进行选择。若用户在爱比熊网站购买相关服务，还须同意及遵守相关服务协议和规则。',
        },
    ],
};

export const catalog = [
    {
        title: '账号注册',
        link: '#register',
    },
    {
        title: '账号注册和核准',
        link: '#account',
    },
    {
        title: '使用规则',
        link: '#rule',
    },
    {
        title: '服务内容',
        link: '#service',
    },
    {
        title: '个人数据保护与授权',
        link: '#personal-data',
    },
    {
        title: '免责声明',
        link: '#no-re',
    },
    {
        title: '知识产权和其他合法权益',
        link: '#property',
    },
    {
        title: '其他',
        link: '#other',
    },
];

export const contract = [
    {
        title: '账号注册',
        id: 'register',
        desc: [
            {
                paragraph: '为使用爱比熊提供的服务，您应当阅读并遵守本协议，以及《快客多隐私使用说明》等内容。',
            },
            {
                paragraph: '您应当同意本协议的条款并按照页面上的提示完成全部的注册程序。您在进行注册程序或第三方授权登录程序过程中勾选协议即表示您与爱比熊达成协议，完全接受本协议项下的全部条款约束。',
            },
            {
                paragraph: '当您选择通过第三方授权登录程序进入爱比熊，您需授权爱比熊收集您的账号昵称及头像等标识化的个人信息，授权完成后，您即成为爱比熊的“用户”。当您按照注册页面提示填写信息并注册成功后，您即成为爱比熊的“用户”；爱比熊将给予您一个用户账号，密码由您自行设置。该用户账号和密码由您自行负责保管。您应当对您在爱比熊进行的活动和事件负法律责任。',
            },
            {
                paragraph: '本协议服务条款和公告可由爱比熊定时更新，并按照法定流程进行公示。您在使用相关服务时，应关注并遵守其所适用的相关条款。',
            },
            {
                paragraph: '使用同一身份认证信息、同一手机号或经爱比熊排查认定多个爱比熊账户的实际控制人为同一人的，均视为同一用户。',
            },
            {
                paragraph: '您确认，在您开始使用本服务前，您应当具备中华人民共和国法律规定与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您需要在您监护人的陪同下完成注册，并且您与您的监护人应依照法律规定承担因此而导致的一切后果。',
            },
        ],
    },
    {
        title: '注册信息和隐私保护',
        id: 'account',
        desc: [
            {
                paragraph: '您在使用快客多账号的所有权归爱比熊，使用权归您。您按注册页面引导填写信息，阅读并同意本协议且完成全部注册程序后，即可获得注册账号并成为用户。您应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实或更新不及时而引发的相关问题，由您自行承担相应的责任，且使用快客多的服务时，必须遵守中华人民共和国相关法律法规的规定，您应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为',
            },
            {
                paragraph: '您应当通过真实身份信息认证注册账号，且您提交的账号名称、头像、简介等注册信息中不得出现违法和不良信息，经公司审核，如存在上述情况，爱比熊将不予注册；同时，在注册后，如发现您以虚假信息骗取账号名称注册，或其账号头像、简介等注册信息存在违法和不良信息的，爱比熊有权不经通知单方采取限期改正、暂停使用、注销登记、收回等措施。',
            },
            {
                paragraph: '您账号包括账户名称和密码，账户名称作为您有效的身份凭证之一。您可使用手机号和密码登录。',
            },
            {
                paragraph: '您不应将其账号、密码转让、出售或出借予他人使用，若您授权他人使用账户，应对被授权人在该账户下发生所有行为负全部责任。由于您其他账户使用信息，仅当依法律法规、司法裁定或经爱比熊同意，并符合爱比熊规定的用户账号转让流程的情况下，方可进行账号的转让。',
            },
            {
                paragraph: '因您个人原因导致的账号信息遗失，如需找回账号信息，请按照账号找回要求提供相应的信息，并确保提供的信息合法真实有效，若提供的信息不符合要求，无法通过安全验证，爱比熊有权拒绝提供账号找回服务；若账号的唯一凭证不再有效，爱比熊有权拒绝支持账号找回。例如手机号二次出售，爱比熊可拒绝支持帮助找回原手机号绑定的账号。',
            },
            {
                paragraph: '在需要终止使用账号服务时，符合以下条件的，您可以申请注销您的账号：',
                id: 'account-1',
                desc: [
                    {
                        paragraph: '您仅能申请注销您本人的账号，并依照爱比熊的流程进行注销；',   
                    },
                    {
                        paragraph: '您仍应对您在注销账号前且使用的行为承担相应责任，同时爱比熊仍可保存您注销前的相关信息；'
                    },
                    {
                        paragraph: '注销成功后，账号信息、个人身份信息、交易记录、会员权益等将无法恢复或提供。'
                    }
                ]
            },
            {
                paragraph: '当您使用爱比熊产品进行支付、登录等操作的时候，服务器会自动接收、验证、并记录一些必要的信息，如手机号码、IP地址、服务访问异常信息、以及部分设备信息等，以保障您在使用服务时账户登录和支付过程的安全，进而保护您的上网安全。您使用快客多的服务，即表示您同意爱比熊可以按照《快客多隐私使用说明》处理您的个人信息。爱比熊可能会与合作伙伴共同向您提供您所要求的服务或者共同向您展示您可能感兴趣的内容。在信息为该项产品/服务所必须的情况下，您同意爱比熊可与其分享必要的信息。并且，爱比熊会要求其确保数据安全并且禁止用于任何其他用途。除此之外，爱比熊不会向任何无关第三方提供或分享信息。'
            },
            {
                paragraph: '您知悉并授权，爱比熊仅在必需的情况下使用或与关联公司同步您的信息，以为您提供更好的服务。'
            },
            {
                paragraph: '为更好地向您提供服务，您同意爱比熊通过短信、app通知等形式向您发送相关商业性服务信息。'
            }
        ],
    },
    {
        title: '使用规则',
        id: 'rule',
        desc: [
            {
                paragraph: '您在使用快客多的服务时，必须遵守中华人民共和国相关法律法规的规定，您应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为:',
                id: 'rule-1',
                desc: [
                    {
                        paragraph: '您设置的会员名或提供的信息不得含有下列内容之一的信息：',
                        id: 'rule-1-1',
                        desc: [
                            '反对宪法所确定的基本原则的；',
                            '危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；',
                            '损害国家荣誉和利益的；',
                            '煽动民族仇恨、民族歧视、破坏民族团结的；',
                            '破坏国家宗教政策，宣扬邪教和封建迷信的；',
                            '散布谣言，扰乱社会秩序，破坏社会稳定的；',
                            '侮辱或者诽谤他人，侵害他人合法权利的；',
                            '含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；',
                            '含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；',
                        ]
                    }
                ]
            },
            '不得为任何非法目的而使用爱比熊服务系统；',
            {
                paragraph: '不利用快客多的服务从事以下活动：',
                id: 'rule-2',
                desc: [
                    '未经允许 ，进入计算机信息网络或者使用计算机信息网络资源的；',
                    '未经允许，对计算机信息网络功能进行删除、修改或者增加的；',
                    '未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；',
                    '故意制作、传播计算机病毒等破坏性程序的；',
                    '其他危害计算机信息网络安全的行为。',
                ]
            },
            '您违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿爱比熊与合作公司、关联公司，并使之免受损害。对此，爱比熊有权视您的行为性质，采取包括但不限于删除您发布信息内容、暂停使用许可、终止服务、限制使用、回收账号、追究法律责任等措施。对恶意注册账号或利用账号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，爱比熊有权回收其账号。同时，爱比熊会视司法部门的要求，协助调查。',
            '您需要对自己在服务使用过程中的行为负法律责任，即使您已经终止使用该服务。您承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在爱比熊首先承担了因您的行为导致的行政处罚或侵权损害赔偿责任后，您应给予爱比熊等额的赔偿。',
            '爱比熊有权根据认定程序及标准判断您账户的使用可能危及您的账户安全及/或爱比熊平台信息安全的，爱比熊可拒绝提供相应服务或终止本协议。'
        ],
    },
    {
        title: '服务内容',
        id: 'service',
        desc: [
            '您在交易过程中与其他用户发生争议的，您或其他用户中任何一方可以自行协商解决，也可以通过爱比熊介入处理，若您觉得以上的方式均无法达到您要的结果，您可以寻求司法机关的协助。',
            '您选择爱比熊介入的方式处理相关问题，那么您需要遵守和执行爱比熊的处理结果。若您对爱比熊的处理结果不满意，您可以寻求其他途径解决纠纷例如起诉、仲裁等方式，但在此之前您需先履行该结果。',
            '爱比熊目前为您提供免费提供服务，但爱比熊保留因业务调整或者其他法律法规要求向您收取费用的权利。',
            '爱比熊网络服务的具体内容由爱比熊根据实际情况提供。',
            '鉴于网络服务的特殊性，您同意爱比熊有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。爱比熊不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。',
            '终止服务，您同意爱比熊得基于其自行之考虑，因任何理由，或爱比熊认为您已经违反本服务协议的文字及精神，终止您的密码、账号或本服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除并删除。您同意依本服务协议任何规定提供之本服务，无需进行事先通知即可中断或终止，您承认并同意，爱比熊可立即关闭或删除您的账号及您账号中所有相关信息及文件，及/或禁止继续使用前述文件或本服务。此外，您同意若本服务之使用被中断或终止或您的账号及相关信息和文件被关闭或删除，爱比熊对您或任何第三人均不承担任何责任。'
        ],
    },
    {
        title: '个人数据保护与授权',
        id: 'personal-data',
        desc: [
            '为了保护您的财产及信息安全，预防诈骗或者其他网络犯罪行为，爱比熊有权收集您的个人信息，包括但不限于个人会员信息、交易信息来判断您个人的交易风险，对您身份进行验证等。',
            {
                id: 'personal-data-1',
                paragraph: '根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：',
                desc: [
                    '涉及公共安全，与重大利益相关的；',
                    '与政府部门包括司法机关等相关的；',
                    '出于保护您的个人财产或者利益的目的，但无法事先征求您同意的行为；',
                    '您以自行对外公开的，包括但不限于通过报纸、新闻等方式公开；',
                    '根据您的要求签订合同所必需的；',
                    '用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；',
                    '国家法律法规政策认可或支持的相关行为；',
                ]
            },
            '我们已采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。'
        ],
    },
    {
        title: '免责声明',
        id: 'no-re',
        desc: [
            {
                paragraph: '因以下情况造成网络服务在合理时间内的中断，爱比熊无需为此承担任何责任；',
                id: 'no-re-1',
                desc: [
                    '爱比熊需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，爱比熊保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。',
                    '因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；',
                    '您的电脑软硬件和通信线路、供电线路出现故障的；',
                    '因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。',
                ],
            },
            '尽管有前款约定，爱比熊将采取合理行动积极促使服务恢复正常。'
        ],
    },
    {
        title: '知识产权和其他合法权益',
        id: 'property',
        desc: [
            '爱比熊产品及相关网站上由爱比熊上传、制作、拥有的所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由爱比熊或其关联公司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。但如因您上传的由其自行制作、拥有的内容涉及侵犯爱比熊或其他任何第三方的合法权益的，您应自行对其侵权行为产生的纠纷进行处理，并对其侵权行为承担法律责任，且就由此给爱比熊造成的损失（包括但不限于爱比熊声誉的影响、爱比熊由此承担的连带责任（如有）等）进行赔偿。',
            '非经爱比熊或其关联公司书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表在本网站上程序或内容（仅限于由爱比熊上传、制作、拥有的所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计等）；如您需使用著作权非爱比熊所有的内容的，您应获得具体内容的著作权所有者的合法授权才能使用，如因您私自使用非自己所有的、且未经他人合法授权的著作、图片、档案、资讯、资料等内容的，由您自行承担责任，包括但不限于您自行对其侵权行为产生的纠纷进行处理，并对其侵权行为承担法律责任，且就由此给乙方造成的损失（包括但不限于乙方声誉的影响、乙方由此承担的连带责任（如有）等）进行赔偿。',
            '尊重知识产权是您应尽的义务，如有违反，您应承担损害赔偿责任。',
            '您理解并同意，本协议一经生效，爱比熊公司即有权永久的、免费的使用您的商业名称、商标、Logo等信息作为爱比熊的宣传推广案例。'
        ],
    },
    {
        title: '其他',
        id: 'other',
        desc: [
            '本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例和（或）行业惯例。',
            '如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余条款则仍具有法律效力。',
            '本协议任何一方于另一方过失或违约时放弃本协议规定的权利的，不得视为其对一方的其他或以后同类之过失或违约行为弃权。',
            '爱比熊未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。',
            '本协议最终解释权及修订权在法律范围内归爱比熊所有。'
        ],
    },
];
