import React from 'react';
import './style.less'
const clsPrefix = 'cn-homepage-grow-wrapper'

export default function(){
  return (
    <>
      <div className={clsPrefix}>
        <div className={`${clsPrefix}-content-wrapper`}>
          <p>快客多</p>
          <p>会员成长体系</p>
          <div className={`${clsPrefix}-content-wrapper-hr`}></div>
        </div>
      </div>
    </>
  )
}