import img1 from '@images/service/img1.png'
import img2 from '@images/service/img2.png'
import img3 from '@images/service/img3.png'
import img4 from '@images/service/img4.png'

export const serviceListData = [
  {
    imgUrl: img1,
    tit:'专业客服答疑',
    text: ['在线技术服务','保障问题高效、快速的解决','让用户没有后顾之忧']
  },
  {
    imgUrl: img2,
    tit:'运营支持',
    text: ['专属运营提供营销帮助','给用户提供最优质的服务']
  },
  {
    imgUrl: img3,
    tit:'售后协助',
    text: ['全程跟踪店铺经营','保障快速、高效解决问题']
  },
  {
    imgUrl: img4,
    tit: '同行交流活动',
    text: ['社群、直播、沙龙等','同行业分享经验']
  }
]