import carousel1x1 from '@images/carousel/carousel11.png'
import carousel1x2 from '@images/carousel/carousel12.png'
import carousel1x3 from '@images/carousel/carousel13.png'
import carousel1x4 from '@images/carousel/carousel14.png'

import carousel2x1 from '@images/carousel/carousel21.png'
import carousel2x2 from '@images/carousel/carousel22.png'
import carousel2x3 from '@images/carousel/carousel23.png'
import carousel2x4 from '@images/carousel/carousel24.png'


const navsList = [
  {
    name: '首页',
    linkTo: '#'
  },
  {
    name: '商家服务',
    linkTo: '#'
  },
  {
    name: '加盟我们',
    linkTo: '#'
  },
  {
    name: '最新动态',
    linkTo: '#'
  }
]

const carousel = [
	{
		lable: {
      content: '快客多—您身边的会员管家',
      color: '#fff'
    },
		des: {
      content: ['专注于会员的电商SaaS平台','为商家提供裂变的会员管理工具和营销插件'],
      color: '#fff'
    },
    imgSrc1: carousel1x1,
    imgSrc2: carousel2x1
  },
  {
		lable: {
      content: '会员体系',
      color: '#fff'
    },
		des: {
      content: ['吸引顾客持续消费，增加购物频次','用专享权益提升会员优越感，积累更多忠实客户'],
      color: '#fff'
    },
    imgSrc1: carousel1x2,
    imgSrc2: carousel2x2

  },
  {
		lable:{
      content: '营销模块',
      color: '#333333'
    },
		des: {
      content: ['拉新、转化、复购面面俱到','多种营销工具助力成交'],
      color: '#5D5D5C'
    },
    imgSrc1: carousel1x3,
    imgSrc2: carousel2x3

	},
  {
		lable: {
      content: '个性化店铺',
      color: '#1A1A1A'
    },
		des: {
      content: ['可视化组件自由拖拽','打造符合品牌调性的个性化店铺'],
      color: '#1A1A1A'
    },
    imgSrc1: carousel1x4,
    imgSrc2: carousel2x4

	},
]

export { navsList, carousel }