import React from 'react';
import { general, catalog, contract } from './data';

import './style.less';
import Logo from './images/logo.png';

const prefixCls = 'contract';

const renderList = (data, tag, deep = 0, prefix = '') => (
    <div key={tag} id={data.id} className={`${prefixCls}-general`}>
        {
            deep === 0 && (
                data.id || data.title === '总则' ? <h4>{data.title}</h4> : <h6>{data.title}</h6>
            )
        }
        <div className={`${prefixCls}-line`} />
        <ul className={`${prefixCls}-general-list ${prefix !== '' ? `${prefixCls}-general-list-children` : ''}`}>
            {
                data.desc.map((item, key) => (
                    <li key={key}>
                        {prefix === '' ? undefined : `${prefix}`}
                        {`${key + 1}. ${typeof item === 'string' ? item : item.paragraph}`}
                        {item.desc && (
                            renderList(item, item.id, deep + 1, `${key + 1}.${prefix}`)
                        )}
                    </li>
                ))
            }
        </ul>
    </div>
);

const Contract = () => (
    <div className={prefixCls}>
        <div id="contract" className={`${prefixCls}-content`}>
            <img src={Logo} className={`${prefixCls}-logo`} alt="logo" />
            <h3>快客多服务协议</h3>
            {
                renderList(general)
            }
            <div className={`${prefixCls}-catalog`}>
                <h5>目录</h5>
                <div className={`${prefixCls}-tolink`}>
                    {
                        catalog.map((item, key) => (
                            <a key={key} href={item.link}>{item.title}</a>
                        ))
                    }
                </div>
            </div>
            <div className={`${prefixCls}-main`}>
                {
                    contract.map((item, key) => (
                        renderList(item, key)
                    ))
                }
            </div>
        </div>
        <div className={`${prefixCls}-footer`}>
            <p>
            Copyright@2019 Kuaizhan.com Inc. All Rights Reserved. 云站科技 版权所有 北京云站科技有限公司
            </p>
            <p>京ICP备18063655号-1</p>
        </div>
        <a href="#contract" className={`${prefixCls}-top`}>top</a>
    </div>
);

export default Contract;
