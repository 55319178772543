// 为三个section 使用的数据
import img1 from '@images/detailsection/img1.png'
import img2 from '@images/detailsection/img2.png'
import img3 from '@images/detailsection/img3.png'
import bg13 from '@images/detailsection/bg13.png'
import bg2 from '@images/detailsection/bg2.png'

import icon11 from '@images/detailsection/first/icon1.png'
import icon12 from '@images/detailsection/first/icon2.png'
import icon13 from '@images/detailsection/first/icon3.png'

import icon21 from '@images/detailsection/second/icon1.png'
import icon22 from '@images/detailsection/second/icon2.png'
import icon23 from '@images/detailsection/second/icon3.png'

import icon31 from '@images/detailsection/third/icon1.png'
import icon32 from '@images/detailsection/third/icon2.png'
import icon33 from '@images/detailsection/third/icon3.png'
import icon34 from '@images/detailsection/third/icon4.png'
import icon35 from '@images/detailsection/third/icon5.png'
import icon36 from '@images/detailsection/third/icon6.png'
import icon37 from '@images/detailsection/third/icon7.png'
import icon38 from '@images/detailsection/third/icon8.png'
import icon39 from '@images/detailsection/third/icon9.png'
import icon310 from '@images/detailsection/third/icon10.png'
import { px2rem } from './util'

// 每个 section 分为 左右两个区域 imgContent 和 cardContent, 分别进行相应的配置
const detailSectionConfigList = [
	{
		isReverse: false,
		imgContent: {
			bgUrl: bg13,
			imgUrl:img1,
			imgW: px2rem(664)
		},
		cardContent: {
			tit: '营销模式',
			barColor: '#E4B200',
			iconsAndText: [
				{
					iconUrl: icon11,
					text: '积分商城',
					subText: ['利用积分商城搭建专属积分体系','吸引忠实、活跃用户']
				},
				{
					iconUrl: icon12,
					text: '优惠券',
					subText: ['自定义配置金额、使用门槛、发券数量','拉动店铺人气，促使客户快速成交']
				},
				{
					iconUrl: icon13,
					text: '多人拼团',
					subText: ['引导客户邀请好友一起购买','可实现系统匹配匿名买家，促使成团']
				}
			],
			iconsAndLables: null,
			hasBtn: true
		}
	},
	{
		isReverse: true,
		imgContent: {
			bgUrl: bg2,
			imgUrl: img2,
		},
		cardContent: {
			tit: '个性化店铺',
			barColor: '#EB4B4B',
			iconsAndText: [
				{
					iconUrl: icon21,
					text: '店铺装修',
					subText: ['多种行业模板','打造符合自己品牌调性的店铺']
				},
				{
					iconUrl: icon22,
					text: '商品分组',
					subText: ['自定义分组对商品归类','便于统计分析商品数据']
				},
				{
					iconUrl: icon23,
					text: '配送模式',
					subText: ['可使用运费模板','结合店铺特性设置运费模式']
				}
			],
			iconsAndLables: null,
			hasBtn: true
		}
	},
	{
		isReverse: false,
		imgContent: {
			bgUrl: bg13,
			imgUrl: img3,
		},
		cardContent: {
			tit: '服务覆盖领域',
			barColor: '#E4B200',
			iconsAndText: null,
			iconsAndLables: [
				[
					{
						iconUrl: icon31,
						lable: '生鲜蔬果'
					},
					{
						iconUrl: icon32,
						lable: '美妆美业'
					},
					{
						iconUrl: icon33,
						lable: '酒店旅游'
					},
					{
						iconUrl: icon34,
						lable: '数码家电'
					},
					{
						iconUrl: icon35,
						lable: '休闲娱乐'
					},

				],[
					{
						iconUrl: icon36,
						lable: '日用百货'
					},
					{
						iconUrl: icon37,
						lable: '茶饮酒水'
					},
					{
						iconUrl: icon38,
						lable: '婚庆摄影'
					},
					{
						iconUrl: icon39,
						lable: '媒体服务'
					},
					{
						iconUrl: icon310,
						lable: '培训教育'
					}
				]
			],
			hasBtn: false
		}
	}
]


export default detailSectionConfigList