import React from 'react';
import './style.less';
import HomeHeader from './HomeHeader'
import HomeMicroShop from './HomeMicroShop'
import HomeVIPSystem from './HomeVIPSystem'
import HomeGrow from './HomeGrow'
import HomeDetailSection from './HomeDetailSection'
import HomeService from './HomeService'
import HomeFooter from './HomeFooter'


const clsPrefix='cn-homepage';





export default function HomePage() {

	return (
		<div className={clsPrefix}>
			<HomeHeader/>
			<HomeMicroShop/>
			<HomeVIPSystem/>
			<HomeGrow/>
			<HomeDetailSection/>
			<HomeService/>
			<HomeFooter/>
		</div>
	)
}