import { isObject } from '@utils/tools';
import { message } from 'antd';
// import { UserRouters } from '@/routers';

const isEmpty = (o) => o === undefined || o === null;

/**
 * 将普通的对象转成 FromData
 */
const convertFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    // 过滤掉值为 undefined 或者 null
    if (!isEmpty(data[key])) {
      formData.append(key, data[key]);
    }
  });
  return formData;
}

/**
 * 将普通的对象转成 URLSearchParams
 * @param {*} data 
 */
const convertURLSearchParams = (data) => {
  const insURLSearchParams = new URLSearchParams();
  Object.keys(data).forEach(key => {
    // 过滤掉值为 undefined 或者 null
    if (!isEmpty(data[key])) {
      insURLSearchParams.append(key, data[key]);
    }
  });
  return insURLSearchParams;
}

function validateStatus(response) {
  return response.data.code === 200;
}

export function defaultRequestResolve(config) {
  let newConfig = config;

  if ((config.method === 'post' 
        && !config.jsonType) 
        && isObject(config.data)) {
    newConfig = {
      ...newConfig,
      data: convertFormData(config.data)
    }
  }
  
  if (config.method === 'put' 
        && !config.jsonType
        && isObject(config.data)) {
    newConfig = {
      ...newConfig,
      data: convertURLSearchParams(config.data)
    }
  }

  if (config.method === 'get' && isObject(config.params)) {
    newConfig = {
      ...newConfig,
      // 过滤掉空字符串
      params: Object.keys(config.params).reduce((acc, key) => config.params[key] ? ({
        ...acc,
        [key]: config.params[key]
      }) : acc, {})
    }
  }

  return newConfig;
}

export function defaultRequestReject(error) {
  return Promise.reject(error);
}

export function defaultResponseResolve(response) {

  if (validateStatus(response)) {
    return response.data.data;
  }

  if (!response.config.errorSlient) {
    setTimeout(() => {
      message.error(response.data.msg);
    }, 0)
  }

  throw new Error(response.data.code);
}

export function defaultResponseReject(error) {

  if (error.response && error.response.status === 401) {
    // UserRouters.login(true);
    return
  }

  if (error.config.errorSlient) {
    return
  }

  const errorMessage = error.config.errorMessage ?
                error.config.errorMessage :
                error.response ? error.response.data.msg : '连接超时';
  message.error(errorMessage);
  return Promise.reject(error);
}

export function loadDefaultIntercetors(baseReq) {
  baseReq
    .interceptors
    .request
    .use(defaultRequestResolve, defaultRequestReject);

  baseReq
    .interceptors
    .response
    .use(defaultResponseResolve, defaultResponseReject);
}