import React, {useState, useEffect} from 'react';
import { isChangeByScreenSize } from '../utils'

export default function(props){
  const { srcSmall, srcLarge, classname } = props.config
  const [isSrcLargeDone, setIsSrcLargeDone] = useState(false)
  
  useEffect(()=>{
    if( isChangeByScreenSize() ){
      const img = new Image()
      img.src = srcLarge;
      img.onload = function(){
        setIsSrcLargeDone(true)
      }
    }
  }, [])
  return (
    <>
      {
        isSrcLargeDone  ? (
          <img className={classname} src={srcLarge} alt=''/>
        ) : (
          <img className={classname} src={srcSmall} alt='' />
        )
      }
    </>
  )
}