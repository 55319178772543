import React from 'react';
import './App.css';
import RootComponent from '@/RootComponent';
import '@kuaizhan/kedit-font';

function App(){
    return <RootComponent />
}

export default App;
