
export const px2rem = px =>{
  // 1rem = html.fontsize px
  // 1px = 
  const rootFontSize = document.getElementsByTagName('html')[0].style.fontSize

  const ration = 1 / parseInt(rootFontSize.replace('px', ''))
  //
  return `${px * ration}rem`
}

