import React, { useState } from 'react';
import './style.less'
import QRcode1 from '@images/footer/qrcode1.png'
import QRcode2 from '@images/footer/qrcode2.png'
import { ShopAPI } from '@requests'
import { message } from 'antd'

const clsPrefix = 'cn-homepage-footer-wrapper'

export default function(){
  const [userPhone, setUserPhone] = useState('')

  function handleJoin() {
		if (userPhone) {
			ShopAPI.postJoin(userPhone).then(() => {
					message.success('提交成功')
				})
		} else {
			message.error('请填写完整');
		}
	}


  return (
    <div className={clsPrefix}>
      <div className={`${clsPrefix}-phone-wrapper`}>
        <div className={`${clsPrefix}-phone-wrapper-phone`}>
          <p>立即免费试用！</p>
          <div className={`${clsPrefix}-phone-wrapper-phone-inp-wrapper`}>
            <input className={`${clsPrefix}-phone-wrapper-phone-inp-wrapper-inp`} placeholder='请输入您的手机号' onChange={(e) => {setUserPhone(e.target.value)}}/>
            <div className={`${clsPrefix}-phone-wrapper-phone-inp-wrapper-btn`} onClick={handleJoin}>免费试用</div> 
          </div>
        </div>
      </div>
      <div className={`${clsPrefix}-footer`}>
        <div className={`${clsPrefix}-footer-content-wrapper`}>
          <div className={`${clsPrefix}-footer-content-wrapper-wechat`}>
            <p>联系我们</p>
            <div className={`${clsPrefix}-footer-content-wrapper-wechat-qrcode`}>
              <img src={QRcode1} alt='' />
              <p>客服人员</p>
            </div>
            <div className={`${clsPrefix}-footer-content-wrapper-wechat-qrcode`}>
              <img src={QRcode2} alt='' />
              <p>微信服务号</p>
            </div>
          </div>
          <div className={`${clsPrefix}-footer-content-wrapper-mail`}>
            <a href="mailto:lulin@icloudfuture.com">商务合作：lulin@icloudfuture.com</a>
            <a href="mailto:huangsiyu@icloudfuture.com">市场合作：huangsiyu@icloudfuture.com</a>
          </div>
          <div className={`${clsPrefix}-footer-content-wrapper-telephone`}>
            <p>免费客服热线</p>
            <h1>400-780-9680</h1>
            <p>周一至周五 9:30-18:30</p>
          </div>
          <div className={`${clsPrefix}-info-wrapper`}>
            <div className={`${clsPrefix}-info-wrapper-info`} style={{height: '1px'}}>
              <p>Copyright@2019 Kuaikeduo.kuaizhan.com Inc. All Rights Reserved.快客多 版权所有 北京爱比熊科技有限公司</p>
              <a href="http://beian.miit.gov.cn">京ICP备18046123号-2</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}