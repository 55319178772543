import React from 'react';
import './style.less'
import detailSectionConfigList from './detailSectionData'
const clsPrefix='cn-homepage-detail-wrapper';
function chooseSize(index){
  switch (index) {
    case 0: return { img: 'img1', bg: 'bg12' };
    case 1: return { img: 'img2', bg: 'bg12' };
    case 2: return { img: 'img3', bg: 'bg3' };
    default: return;
  }
};
function handleClickToFooter(){
  window.scrollTo(0, 5612);
}
const sectionList = detailSectionConfigList.map((item, index)=>{
  
  return (
    <>
      <div key={index} className={`${clsPrefix}`} style={ item.isReverse ? {flexDirection:'row-reverse'} : {flexDirection:'row'} }>
        <div className={`${clsPrefix}-img-wrapper`}>
          <div className={`${clsPrefix}-img-wrapper-mask ${chooseSize(index).bg}`} ></div>
          <img className={`${clsPrefix}-img-wrapper-bg ${chooseSize(index).bg}`} src={item.imgContent.bgUrl} alt='' />
          <img className={`${clsPrefix}-img-wrapper-img img1 ${chooseSize(index).img}`} src={item.imgContent.imgUrl}  alt='' />
        </div>

        <div className={`${clsPrefix}-card-wrapper`} style={item.isReverse ? {borderLeft: `16px solid ${item.cardContent.barColor}` }: { borderRight: `16px solid ${item.cardContent.barColor}` }}>
          <div className={`${clsPrefix}-card-wrapper-content-wrapper`}>
            <h1 className={`${clsPrefix}-card-wrapper-tit`} style={ item.isReverse ? { } : { color: '#EB4B4B' } }>{item.cardContent.tit}</h1>
            <span className={`${clsPrefix}-card-wrapper-hr`}></span>
            {
              // 分情况
              item.cardContent.iconsAndText ? 
                item.cardContent.iconsAndText.map((item, index)=>{
                  return (
                    <div className={`${clsPrefix}-card-wrapper-item`} key={index}>
                      <img className={`${clsPrefix}-card-wrapper-item-icon`} src={item.iconUrl} alt='' />
                      <div className={`${clsPrefix}-card-wrapper-item-text-wrapper`}>
                        <h2 className={`${clsPrefix}-card-wrapper-item-text-wrapper-text`}>{item.text}</h2>
                        {
                          item.subText.map((item, index) =>{
                            return (
                              <>
                                <p className={`${clsPrefix}-card-wrapper-item-text-wrapper-subtext`}>{item}</p>
                              </>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                }) : 
                

                <div className={`${clsPrefix}-card-wrapper-icons-wrapper`}>
                  
                  {item.cardContent.iconsAndLables.map((list, index)=>{
                    return (
                      <>
                        <div className={`${clsPrefix}-card-wrapper-icons-wrapper-iconbox-wrapper`}>
                          {
                            list.map((icon,index)=>{
                              return (
                                <>
                                  <div className={`${clsPrefix}-card-wrapper-icons-wrapper-iconbox-wrapper-iconbox`}>
                                    <img src={icon.iconUrl} alt='' className={`${clsPrefix}-card-wrapper-icons-wrapper-iconbox-wrapper-iconbox-icon`}/>
                                    <span className={`${clsPrefix}-card-wrapper-icons-wrapper-iconbox-wrapper-iconbox-lable`}>{icon.lable}</span>
                                  </div>
                                </>
                              )
                            })
                          }
                        </div>
                      </>
                    )
                  })}
                </div>

            }
            
            {
              item.cardContent.iconsAndText ? (
              <div className={`${clsPrefix}-card-wrapper-btn pointer`} onClick={handleClickToFooter}>
                <p>免费试用</p>
                <div></div>
              </div>  
              ): 
              (
                <>
                  <div className={`${clsPrefix}-card-wrapper-btn pointer`} onClick={handleClickToFooter} style={{marginTop: '10px', marginBottom: '0px'}}>
                    <p>免费试用</p>
                    <div></div>
                  </div>  
                  <div style={{width: '100%', height: '0px'}}></div>
                </>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
})


export default function(){
  return (
    <>
      {sectionList}
    </>
  )
}