import React, { useRef, useState, useEffect } from 'react';
import { Carousel } from 'antd'
import './style.less'
import { 
  carousel 
} from './headerData'
import { ShopAPI } from '@requests'


const clsPrefix='cn-homepage-header';

export default function HomeHeader(){
  const slider = useRef(null)

  const [user, setUser] = useState({})
  useEffect(() => {
		ShopAPI.getCurrentBUser(true).then((data) => {
			setUser(data || {})
		}).catch(() => {
      console.log('no login')
    });
	}, [])

  function jumpLogin() {
		window.location.href = `/v1/shop/login?from=${encodeURIComponent('http://kuaikeduo.kuaizhan.com/v1/shop/list')}`
	}

	function jumpRegister() {
		window.location.href = `/v1/shop/register?from=${encodeURIComponent('http://kuaikeduo.kuaizhan.com/v1/shop/list')}`
	}

	function jumpManage() {
		window.location.href = '/v1/shop/list'
  }
  function handleClickToFooter(){
    window.scrollTo(0, 5612);
  }


  const carsouselList = carousel.map((item, index)=>{
    return (
      <div key={index} className={`${clsPrefix}-carousel-wrapper`}>
        <div className={`${clsPrefix}-carousel-img-wrapper`} style={{backgroundImage: `url(${item.imgSrc1})`}}>
          <div className={`${clsPrefix}-carousel-img-wrapper-img`}>
            <span className={`${clsPrefix}-carousel-lable`} style={{color: item.lable.color}}>{item.lable.content}</span>
            <div className={ `${clsPrefix}-carousel-p-wrapper`}>
              {
                item.des.content.map((desItem, num)=>{
                  return (
                    <p className={ `${clsPrefix}-carousel-p-wrapper-p`} style={{color: item.des.color}}>{desItem}</p>
                  )
                })
              }
            </div>
            <span className={`${clsPrefix}-carousel-btn pointer`} onClick={handleClickToFooter}>免费试用</span>
          </div>
        </div>
      </div>
    )
  })
  const carouselSetting = {
    autoplay: true,
    dots: true,
    infinite: true
  }




  return (
    <>
      <div className={`${clsPrefix}-top-body-wrapper`}>
        <div className={`${clsPrefix}-top-body`}>
          <div className={`${clsPrefix}-top-left`}>
            <div className={`${clsPrefix}-top-left-logo`}></div>
          </div>
          <div className={`${clsPrefix}-top-right`}>
            <div className={`${clsPrefix}-top-right-btns`}>
              {
                user.phone ? (
                  <>
                    <div onClick={jumpManage} className={`${clsPrefix}-top-right-btn pointer`}>进入管理</div>
                  </>
                ) : (
                  <>
                    <div onClick={jumpLogin} className={`${clsPrefix}-top-right-btn ${clsPrefix}-top-right-btn-actived pointer`}>登录</div>
                    <div onClick={jumpRegister} className={`${clsPrefix}-top-right-btn pointer`} style={{}}>注册</div>
                  </>
                )
              }
            </div>
        </div> 
        </div>
      </div>
      <div className={`${clsPrefix}-carousel`}>
        <Carousel ref={el => (slider.current = el)} {...carouselSetting}>
          {carsouselList}
        </Carousel>
        <div className={`${clsPrefix}-carousel-icons`}>
          <div className={`${clsPrefix}-carousel-icons-btn pointer`} onClick={() => {
            slider.current.prev()
          }}></div>
          <div className={`${clsPrefix}-carousel-icons-btn pointer`} onClick={() => {
            slider.current.next()
          }}></div>
        </div>
      </div>
    </>
  )
}