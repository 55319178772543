import React from 'react';
import { HomePage } from '@containers/HomePage';

export default function HomePag() {
  return (
    <>
      <HomePage />
    </>
  )
}
