export function isChangeByScreenSize(){
  return (document.body.clientWidth || document.documentElement.clientWidth) > 1440
}

export function preLoadImg(src){
  if(isChangeByScreenSize()){
    return new Promise((resolve, reject)=>{
      const img = new Image()
      img.src = src
      img.onload = ()=>{
        resolve(img.src)
      }
      img.onerror = () =>{
        reject('img error')
      }
    })
  }
  return false
}