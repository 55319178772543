import React, { useState, useEffect } from 'react';
import './style.less'
import crown from '@images/vipsystem/crown.png'
import crownAfter from '@images/vipsystem/crown-after.png'
import card from '@images/vipsystem/card.png'
import cardAfter from '@images/vipsystem/card-after.png'
import shield from '@images/vipsystem/shield.png'
import shieldAfter from '@images/vipsystem/shield-after.png'
import bg2 from '@images/vipsystem/bg2x.png'
import { preLoadImg } from '../common'


const clsPrefix='cn-homepage-vip-wrapper';

const vipSystemList = [
  {
    img: crown,
    imgAfter: crownAfter,
    tit: '会员等级',
    lable: ['可定制经验值规则','积分累计规则','积分消耗规则']
  },{
    img: card,
    imgAfter: cardAfter,
    tit: '会员储值',
    lable: ['吸引客户储值并用余额消费','带来持续复购']
  },{
    img: shield,
    imgAfter: shieldAfter,
    tit: '会员管理',
    lable: ['系统化的管理用户','有效掌握会员资料','挖掘意向用户']
  }
]


export default function(){
  const [bg2x, setBg2x] = useState(null)
  useEffect(()=>{
    const img = preLoadImg(bg2)
    if( img ){
      img.then(src=>{
        setBg2x(src)
      }).catch(e=>{
        console.log(e)
      })
    }
  }, [])

  return (
    <div className={`${clsPrefix}`} style={  bg2x ? {backgroundImage: `url(${bg2x})`} : {} }>
      {
        vipSystemList.map(item=>{
          return (
            <>
              <div className={`${clsPrefix}-section-wrapper pointer`}>
                <div className={`${clsPrefix}-section-wrapper-tit`}>会员体系</div>
                <img src={item.img} className={`icon`} alt=''/>
                <img src={item.imgAfter} className={`icon-after`} alt='' />

                <div className={`${clsPrefix}-section-wrapper-text-wrapper`}>
                  <span className={`${clsPrefix}-section-wrapper-text-wrapper-tit`}>{item.tit}</span>
                  {
                    item.lable.map(lable=>{
                      return (
                        <>
                          <span className={`${clsPrefix}-section-wrapper-text-wrapper-lable`}>{lable}</span>
                        </>
                      )
                    })
                  }
                </div>
              </div>
            </>
          )
        })
      }
    </div>
  )
}