import React from 'react';
import './style.less'
import microshopImg from '@images/microshop/microshop.png'
const clsPrefix='cn-homepage-micro-shop-wrapper';

const text = [
  {
    text: '全渠道电商解决方案',
    subtext: '帮您打造以会员为中心的线上营销商城'
  },
  {
    text: '量身定制的会员体系',
    subtext: '打造属于自己的品牌价值和忠实用户'
  },
  {
    text: '客户流量私域化',
    subtext: '帮助商家实现用户和流量的私有化'
  }
]

export default function HomeMicroShop(){
  const textList = text.map((item, index) => {
    return (
      <>
        <p className={`${clsPrefix}-card-text-wrapper-text`}>{item.text}</p>
        <p className={`${clsPrefix}-card-text-wrapper-subtext`}>{item.subtext}</p>
      </>  
    )
  })
  return (
    <>
      <div className={`${clsPrefix}`}>
        <img src={microshopImg} alt='' className={`${clsPrefix}-img`}/>
        <div className={`${clsPrefix}-card`}>
          <span className={`${clsPrefix}-card-logo`}></span>
          <div className={`${clsPrefix}-card-text-wrapper`}>
            <h3 className={`${clsPrefix}-card-text-wrapper-tit`}>快客多微商城</h3>
            <div className={`${clsPrefix}-card-text-wrapper-hr`}></div>
            {textList}
          </div>
        </div>
      </div>
    </>
  )
}