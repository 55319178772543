import React from 'react';
import './style.less'
import { serviceListData } from './serviceListData'

const clsPrefix = 'cn-homepage-service'



export default function(){
  const list = serviceListData.map((item, indexCurrent) =>{
    return (
      <>
        <div className={ `${clsPrefix}-box-wrapper-box pointer` }>
          <div className={`${clsPrefix}-box-wrapper-box-text-wrapper`}>
            <p className={`${clsPrefix}-box-wrapper-box-text-wrapper-tit`}>{item.tit}</p>
            {
              item.text.map(p=>{
                return (
                  <>
                    <p className={`${clsPrefix}-box-wrapper-box-text-wrapper-text`}>{p}</p>
                  </>
                )
              })
            }
          </div>
          <img src={item.imgUrl} className={`${clsPrefix}-box-wrapper-box-img`} alt='' />
        </div>
      </>
    )
  })
  return (
    <>
      <div className={clsPrefix}>
        <div className={`${clsPrefix}-topbar-wrapper`}>
          <div className={`${clsPrefix}-topbar-wrapper-text-wrapper`}>
            <p className={`${clsPrefix}-topbar-wrapper-text-wrapper-redp`}>快客多</p>
            <p className={`${clsPrefix}-topbar-wrapper-text-wrapper-blackp`}>全方位一站式服务</p>
          </div>
        </div>
        <div className={`${clsPrefix}-hr`}></div>
        <div className={`${clsPrefix}-box-wrapper`}>
          {
            list
          }
        </div>
      </div>
    </>
  )
}