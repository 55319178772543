import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { HomePage, ContractPage } from '@pages';

export const ProductContext = React.createContext(null);

function RootComponent() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true} component={HomePage} />
        <Route path="/hp/contract" exact={true} component={ContractPage} />
      </Switch>
    </BrowserRouter>
  )
}

export default RootComponent;
