import axios from 'axios';
import {
  loadDefaultIntercetors
} from './interceptors';

/**
 * 店铺基本
 * http://api-doc.kuaizhan.sohuno.com/project/12/interface/api/cat_63
 */
const baseReq = axios.create({
  baseURL: '/shop/api/v1',
  timeout: 8000
});

const basePlatformReq = axios.create({
  baseURL: '/platform',
  timeout: 8000
});


loadDefaultIntercetors(baseReq);
loadDefaultIntercetors(basePlatformReq);

/**
 * 首页立即加入
 * @param {Object} data
 */
function postJoin(phoneNo) {
  return basePlatformReq.post(
    `/agent/api/v1/agent`, 
    { name: '快客多 - 立即加入', phoneNo, agentType: 'GOLD' }
  )
}

/**
 * bUser profile
 */
function getCurrentBUser(errorSlient = false) {
  return baseReq.get('/me/getCurrentBUser', {
    errorSlient
  });
}


/**
 * 获取店铺列表
 * @param {Object} data
 */
function getList(data) {
  return baseReq.get(`/shops`, data)
}

const APIs = {
  getList,
  postJoin,
  getCurrentBUser
}

export default APIs;
